@mixin sizeRestriction($pxCount) {
    .above-#{$pxCount} {
        @media (max-width: $pxCount) {
            display: none;
        }
    }

    .under-#{$pxCount} {
        @media (min-width: $pxCount) {
            display: none;
        }
    }
}

@include sizeRestriction(700px);
@include sizeRestriction(500px);
@include sizeRestriction(1000px);