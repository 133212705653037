$mainRed: #ff6f00;
$otherColor: #ea9000;
@media (max-width: 1000px) {
    h1 {
        font-size: 11vw;
    }

    header h1.bonjour {
        min-height: 10vw;
    }

    header h1.compagnie {
        min-height: 20vw;
        margin-bottom: 2rem;
    }
}

@media (min-width: 1000px) {
    h1 {
        font-size: 4rem
    }

    header h1 {
        min-height: 5rem;
    }
}


h1 {
    line-height: 100%;
    font-family: 'Source Sans 3', sans-serif;
    text-transform: uppercase;
    font-weight: 800;

    color: $otherColor;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-shadow: darken($mainRed, 80%) 0px 0px 8px;
}

.input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #444;
    opacity: 1;
    /* Firefox */
}

.input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #444;
}

h2.tagline {
    text-transform: uppercase;
    letter-spacing: .8rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1000px) {
    h2.tagline {
        font-size: 1.5rem;
    }

    h2.tagline-narrow {
        display: none;
    }
}

@media (max-width: 1000px) {
    h2.tagline {
        font-size: 3.5vw;
        letter-spacing: .2rem;
        text-align: justify;
    }

    #tagline-wide {
        display: none;
    }

    .tagline-narrow-container {
        border: #ddd solid 0.1vw;
        padding: 1vw 4vw;
    }
}

#toppart {
    margin-bottom: 8rem;
}

header {
    margin-bottom: 1rem;

    text-align: center;

    h1 {
        margin: 0;
    }

}

.bonjour {
    color: $otherColor;
    text-shadow: darken($otherColor, 80%) 0px 0px 8px;
}

.compagnie {
    color: $mainRed;
    text-shadow: darken($mainRed, 80%) 0px 0px 8px;
}

.section {
    padding: 2rem 0.2rem 0.1rem 0.2rem;
}

.cities {
    padding: 0;

    margin: auto;
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 0rem;
    max-width: 600px;
    padding: 0 .5rem;
}

.cities span {
    font-weight: 700;
    font-size: unset !important;
    letter-spacing: .2rem;
}

@media (max-width: 1000px) {
    .cities {
        margin-top: 0rem;

        span {
            font-weight: 700;
            font-size: 1.3rem !important;
        }
    }
}

.appear {
    opacity: 0;
    -webkit-animation: appearAnimation 1s;
    animation: appearAnimation 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

@keyframes appearAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.modal-content {
    font-weight: 80;
    font-size: 1.5rem;
    backdrop-filter: blur(10px);
}


@media (max-width: 1000px) {
    .modal-content {
        font-size: 1.3rem;
    }
}

.modal:not(.is-error .is-ok) .modal-content .box {
    background-color: #555;
    color: #eff;
}

.modal.is-error .modal-content .box {
    background-color: #f449;
    color: #eff;
}

.modal.is-ok .modal-content .box {
    background-color: #3f39;
    color: #fef;
}

/* Login */
h2 {
    text-transform: uppercase;
}

#login-capsule {
    position: absolute;

    right: 2rem;
    top: 3rem;

    @media (max-width: 1000px) {
        top: 1.5rem;
    }

    &>a>button {
        background-color: rgba($mainRed, 0.18);
        color: white;
        border: 2px solid darken($mainRed, 30%);


        @media (max-width: 1000px) {
            width: 11vw !important;
            height: 11vw;
            padding: 20%;
        }

        width: 8vh !important;
        height: 8vh;
        padding: 20%;

        img {
            max-width: 100%;
            max-height: 100%;
            padding-right: 6%;
            filter: opacity(0.75);
        }

        &:hover {
            background-color: darken(rgba($mainRed, 0.7), 5%);
            color: darken(white, 10%);

            img {
                filter: opacity(0.9) brightness(90%);
            }
        }


    }
}

.bottomPart {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.comingSoon {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}