#imagesContainer {
    overflow: hidden;
    align-items: stretch;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .miniature {
        margin-right: 0;
        padding: 0;
        margin-left: 0;
        display: inline-flex;

        text-align: center;
        overflow: hidden;
    }

    .miniature img {
        display: block;
        width: auto;
        height: auto;
        z-index: 10;
        filter: grayscale(100%) blur(2px) invert(0.11);
        //filter: sepia(100%) grayscale(100%) hue-rotate(0deg) brightness(70%) blur(3px) saturate(0.1);

        width: 100%;
        opacity: 1;
        transform: scale(1.02, 1.02);
        transition-timing-function: ease-out;
        transition-duration: 250ms;
        object-fit: cover;
    }

    .miniature:hover img {
        filter: sepia(100%) hue-rotate(300deg) brightness(70%) blur(3px);
        transform: scale(1.08, 1.08);
        transition-timing-function: ease-out;
        transition-duration: 250ms;
        position: relative;
        z-index: 99;
        overflow: hidden;
    }

    .miniature .imgBody {
        position: absolute;
        text-align: center;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;

        min-width: 100%;
        padding: 0 0.3rem 0rem 0.3rem;

        h2 {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            font-weight: 700;
            color: #eee;
            text-shadow: #222 0px 0px 10px, #222 5px 1px 3px;
            letter-spacing: .5rem;

            text-transform: uppercase;

            @media (min-width: 1200px) {
                font-size: 3rem;
            }

            @media (min-width: 1000px) and (max-width:1200px) {
                font-size: 2.2rem;
            }

            @media (max-width: 1000px) {
                font-size: 3.85vw;
                letter-spacing: .6vw;

                text-shadow: #222 0px 0px 10px, #222 2px 1px 3px;
            }
        }

        p {
            margin-top: 1%;
            flex-grow: 1;
            color: #eee;
            text-shadow: #333 0px 0px 4px, #222 0px 0px 8px, #222 2px 0px 3px;

            @media (min-width: 1000px) {
                font-size: 2rem;
            }

            @media (max-width: 1000px) {
                font-size: 3.45vw;
                letter-spacing: .1vw;
            }
        }
    }
}